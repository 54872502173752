/* @media screen and (max-width: 960px) {
    .salleReunionDescription{
        
        width:50%
    }
    .salleReunionImage{
        width:50%
    }
  } */
  .paperBatiment2{
    visibility: hidden;
    width: 10px; 
    height: 5px;
  }
  .map2{
    width: 10px; 
    height: 5px;
  }
  .paperMap2{
    visibility: hidden;
    width: 10px; 
    height: 5px;
  }
 
.gridTop{
    height: 200px;
}
.gridBot{
    height: 200px;
}

.carousel-control-prev {
    visibility: hidden;
}
.carousel-control-next{
    visibility: hidden;
}

.marginTest{
margin-left: 260px;
margin-right: 260px;
}


  @media screen and (max-width: 1280px) {
  .paperBureaux2{
    width: auto
  }
  
  .paperMap2{
    visibility: visible;
    margin-bottom: 20px;
    width: 320px; 
    height: 595px;
  }   
  .map2{
    height: 595px;
    width: auto;
  }
  .paperBatiment2{
      visibility: hidden;
      width: 0px !important;
      margin-bottom: 0px;
  }
  .batiment{
    width: 100% !important
  }
  .paperBatiment{
    visibility: hidden;
  }
  .paperMap{
    visibility: hidden;
    width: 0px;
    height: 0px;
  }

  .marginTest{
    margin-left: 0px;
    margin-right: 0px;
    }
    .gridTop{
      height: 20px;
  }
  .gridBot{
      height: 20px;
  }
}
/* @media screen and (width: 1600px) {
  
  
  .paperMap2{
    visibility: visible;
    margin-bottom: 20px;
    width: 320px; 
    height: 595px;
  }   
  .map2{
    width: 410px; 
    height: 595px;
    width: auto;
  }
  
  .paperMap{
    visibility: hidden;
  }

  .marginTest{
    margin-left: 0px;
    margin-right: 0px;
    }
} */
 @media screen and (max-width: 1675px) {
  
  .paperMap{
    visibility: hidden;
    width: 0px;
    height: 0px;
  }

  .paperMap2{
    visibility: visible; 
    height:595px;
    margin-bottom:20px;  
  }   
  .map2{
    width:412px;
    height:595px;
  }
} 