* {
    padding: 0;
    border: none;
    outline: none;
  }
  .form-contents-label::after{
    content: "*"; 
    color:red;
    
}
  body {
    background: #fff;
  }
  .contact-form {
    margin: 3rem auto 0;
    max-width: 50%;
    max-width: 350px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border: 1px solid #10bb35;
    background: #fff;
    padding: 0 30px 10px;
    box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.637);
  }
  h2 {
    margin-top: 35px;
    text-align: center;
  }
  .form-content {
    display: grid;
    grid-template-rows: repeat(4, 1fr) 105px;
  }
  input,
  textarea {
    background: none;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.1rem;
    border-bottom: 1px solid#cec950;
    color: #000;
  }
  input::placeholder, 
  textarea::placeholder {
   color: rgb(190, 188, 188);
  }
  input,
  .email-content,
  textarea {
    width: 100%;
    margin: 0 auto;
  }
  input {
    padding: 20px 0 0px;
  }
  .email-content {
    position: relative;
  }
  .email-content input {
    width: 100%;
  }

  #not-phone {
    display: none;
    position: absolute;
    top: 4px;
    color: #ff4d4d;
    right: 0;
    transform-origin: 50% 50%;
  }

  #not-mail {
    display: none;
    position: absolute;
    top: 4px;
    color: #ff4d4d;
    right: 0;
    transform-origin: 50% 50%;
  }
  @keyframes dongle {
    0% {
      transform: translate(0px, 0px);
    }
    10% {
      transform: translate(-10px, 0px);
    }
    20% {
      transform: translate(10px, 0px);
    }
    30% {
      transform: translate(-10px, 0px);
    }
    40% {
      transform: translate(10px, 0px);
    }
    50% {
      transform: translate(-10px, 0px);
    }
    60% {
      transform: translate(10px, 0px);
    }
    70% {
      transform: translate(-10px, 0px);
    }
    80% {
      transform: translate(10px, 0px);
    }
    90% {
      transform: translate(-10px, 0px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }
  textarea {
    resize: none;
    padding: 24px 0;
  }
  .button {
    text-align: center;
    padding-top: 0;
    font-size: 1.3rem;
    margin-top: 15px;
    color: #fff;
    background-color: #0c9029;
    border:2px solid #0c9029;
    cursor: pointer;
    transition: .2s;
    width: 100%;
  }
  .button:hover {
    /* letter-spacing: 2px; */
    color: #0c9029;
    background-color: #fff;
  }
  .error::placeholder {
    color: #ff4d4d;
  }
  .form-commentaire {
    margin-top: 10px;
    padding: 12px;
    opacity: 0;
    transition: 0.2s ease;
    color: black;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(51, 51, 51, 0.3);
  }