*, ::after, ::before {
    box-sizing:inherit !important;
 }
 a{
   color: #fff !important;
   text-decoration: none !important;
 }
 .scrolled{
  position: fixed;
  top: 0;
  left: 0;
}
 .navbar {
   background: #1c8e2f;
   width: 100%;
   justify-content: center;
   align-items: center;
   font-size: 1.2rem;
   top: 0;
   left: 0;
   /*   height: 200px;
     display: flex;
     position: sticky;
     */
     z-index: 999;
     min-height: 6vh;
     box-sizing: border-box;
     display: flex;
     transition: all .7s ease-in;
  
   } 
   
    .navbar-container {
      width: 40% !important;
     justify-content: space-between;
     height: 80px;
   }
   
   .container {
     z-index: 1;
     max-width: 1300px;
     width: 40% !important;
     margin: 0px !important;
     padding: 0px !important;
   }
   
   
   .navbar-logo {
     color: #fff;
     justify-self: flex-start;
     cursor: pointer;
     text-decoration: none;
     font-size: 2rem;
     display: flex;
     align-items: center;
   }
   
   .navbar-icon {
     margin-right: 0.5rem;
     max-width: 40%;
     max-height: 150px;
   }
   
   .nav-menu {
     display: flex;
     align-items: center;
     justify-content: center;
     list-style: none;
     text-align: center;
     justify-content: flex-end;
   }
   
   .nav-item {
     height: 80px;
     border-bottom: 2px solid transparent;
   }
   
   .nav-item:hover {
     border-bottom: 2px solid #fff;
   }
   
   .nav-links {
     color: #fff;
     display: flex;
     align-items: center;
     text-decoration: none;
     padding: 0.5rem 1rem;
     height: 100%;
   }
   
   .fa-bars {
     color: #fff;
   }
   
   .menu-icon {
     display: none;
   }
   
    .logo-image{
     width: 60%;
 }
 
 .navbar-container .logo-image{
   display: inline-block;
  vertical-align: top;
 }
 
   @media screen and (max-width: 1024px) {
     .NavbarItems {
       position: relative;
     }
     .navbar{
       height: 100px;
     }
     .nav-menu {
       display: flex;
       flex-direction: column;
       width: 100%;
       height: auto;
       position: absolute;
       top: 80px;
       left: -100%;
       opacity: 1;
       transition: all 0.5s ease;
     }
   
     .nav-menu.active {
       background: #1c8e2f;
       margin-top: 36px;
       padding-left:0px;
       left: 0;
       opacity: 1;
       transition: all 0.6s ease;
       z-index: 1;
     }
   
     .nav-links {
       text-align: center;
       padding: 2rem;
       width: 100%;
       display: table;
     }
   
     .nav-links:hover {
       color: #fff;
       transform: scale(1.2);
       transition: all 0.3s ease;
     }
   
     .nav-item:hover {
       border: none;
     }
   
     .nav-item {
       width: 100%;
     }
   
     .navbar-logo {
       position: absolute;
       top: 0;
       left: 0;
       transform: translate(25%, 50%);
       width: 25%;
     }
   
     .menu-icon {
       display: block;
       position: absolute;
       top: 0;
       left: 10;
       right: 0;
       transform: translate(-100%, 60%);
       font-size: 1.8rem;
       cursor: pointer;
     }
   
     .fa-times {
       color: #fff;
       font-size: 2rem;
     }
   
     .nav-btn {
       display: flex;
       justify-content: center;
       align-items: center;
       width: 100%;
       height: 150px;
     }
     .navbar-icon{
      margin-right: 0.5rem;
      max-width: 90%;
      max-height: 75px;
     }
   }