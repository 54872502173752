.footer-container {
    background: #1c8e2f;
      /* padding: 4rem 0 2rem 0; */
      top: 0; /* for ie8 & below*/
      border-top: none;
      padding-bottom: 10px;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    .footer-subscription {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-bottom: 24px;
      padding: 24px;
      color: #fff;
    }
    
    .footer-subscription > p {
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
        'Lucida Sans', Arial, sans-serif;
    }
    
    .footer-subscription-heading {
      margin-bottom: 24px;
      font-size: 24px;
    }
    
    .footer-subscription-text {
      margin-bottom: 24px;
      font-size: 20px;
    }
    
    .footer-input {
      padding: 8px 24px;
      border-radius: 2px;
      margin-right: 10px;
      outline: none;
      border: none;
      font-size: 18px;
      margin-bottom: 16px;
      border: 1px solid #fff;
    }
    
    .footer-links {
      width: 100%;
      max-width: 1000px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .footer-link-wrapper {
      display: flex;
    }
    
    .footer-link-items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 16px;
      text-align: left;
      width: 160px;
      box-sizing: border-box;
    }
    
    .footer-link-items h2 {
      margin-bottom: 16px;
    }
    
    .footer-link-items > h2 {
      color: #fff;
    }
    
    .footer-link-items a {
      color: #fff;
      text-decoration: none;
      margin-bottom: 0.5rem;
    }
    
    .footer-link-items a:hover {
      color: #e9e9e9;
      transition: 0.3s ease-out;
    }
    
    .footer-email-form h2 {
      margin-bottom: 2rem;
    }
    
    .footer-input::placeholder {
      color: #b1b1b1;
    }
    
    /* Social Icons */
    .social-icon-link {
      color: #fff;
      font-size: 24px;
    }
    
    .social-media {
      max-width: 1000px;
      width: 100%;
    }
    
    .social-media-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      max-width: 1000px;
      margin: 0 auto 0 auto;
    }
    
    .social-icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 240px;
    }
    
    .social-logo {
      color: #fff;
      justify-self: start;
      margin-left: 20px;
      cursor: pointer;
      text-decoration: none;
      font-size: 2rem;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }
    
    .website-rights {
      color: #fff;
      margin-bottom: 16px;
    }
  
    .map{
      width: 400px; 
      height: 280px; 
     
    }
    .footer-logo2{
      visibility: hidden;
    }
  
    .footer-icon{
      margin-right: 0.5rem;
    }
    .h2Hidden{
      visibility: hidden;
    }
  
    @media screen and (max-width: 870px) {
      .footer-container{
        height: 600px;
        padding-bottom: 120px;
      }
      .footer-links {
        padding-top: 2rem;
      }
    
      .footer-input {
        width: 100%;
      }
    
      .btn {
        width: 100%;
      }
    
      .footer-link-wrapper {
        flex-direction: column;
      }
    
      .footer-logo{
        display:none;
      }
      .footer-logo2{
        visibility: visible;
      }
      .social-media-wrap {
        flex-direction: column;
      }
      .map{
        width: 100%;
      }
    }